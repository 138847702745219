.UserAvatar {
    display: inline-block;
    color: white;
    margin-top: 5px;

}

.UserAvatar--inner {
    border-radius: 25% !important;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.UserAvatar--light {
    color: gray;
}

.UserAvatar--light .UserAvatar--inner {
    border: 1px solid lightgray;
}

.UserAvatar--img {
    float: left;
    width: 50px;
    height: 50px;
    border-radius: 25% !important;
    margin-right: 5px;
    display: inline-block;
}
