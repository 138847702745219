@import url(https://fonts.googleapis.com/css?family=Open+Sans);
.FulfillerSelect-Spinner-20 {
    height: 20px;
}

.FulfillerSelect-Spinner-text {
    margin-left: 0.5em;
}

.FulfillerSelect-vertical-center {
    display: inline-flex;
    align-items: center;
}

div.FulfillerSelect-option-group-label {
    box-sizing: border-box;
    background-color: #fff;
    color: #c4cdd6;
    display: block;
    padding-top: 12px;
}

.modal-overflow .modal-body {
  overflow-y: visible;
}

.comment {
    padding-top: 5px;
    padding-bottom: 5px;
    display: grid;
    grid-template-columns: 50px auto;
    grid-gap: 5px
}

.comment .alert {
    margin-bottom: 0px;
}


.comment-editable {
    cursor: pointer;
}

.comment-even {
    background-color: white;
    border-bottom: 1px solid #c4cdd6;
}

.comment-odd {
    background-color: white;
    border-bottom: 1px solid #c4cdd6;
}

.comment:last-child {
    border-bottom-width: 0px;
}

.comments {
    border: 0px solid #c4cdd6;
    border-radius: 0px;
    display: grid;
}

.no-comments {
    padding: 3px;
    background-color: white;
}

.comment-author, .comment-creator {
    display: inline-block;
    margin-left: 3px;
    margin-bottom: 2px;
    font-size: 10px;
    max-width: 500px;
    color: #4e4e4e
}

.comment-creator-inner {
    margin-bottom: 0px;
    margin-top: 0px;
}

.comment-editing {
    width: 100%
}

.inline-spinner {
    display: inline-block;
    vertical-align: middle;
    padding: 2px;
}

.comment-count-badge {
    position: absolute;
    right: -7px;
    top: 7px;
    background: red;
    text-align: center;
    align-content: center;
    border-radius: 30px 30px 30px 30px;
    color: white;
    padding: 2px 4px 1px 3px;
    font-size: 7px;
    line-height: normal;
    -webkit-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
}

.comment-count-badge-loading {
    background: transparent !important;
    color: #0088a9 !important;
}

.comment-indicator {
    font-size: 2em;
    color: #0088a9;
    cursor: pointer;
    position: relative;
    display: inline-block;
}

.comment-drawer-button {
    font-size: 2em;
    color: #0088a9;
    cursor: pointer;
    position: relative;
    padding-top: 5px;
    display: inline-block;
}

.comment-drawer-button:hover {
    color: #0088a9;
}

.mentions__highlighter {
    pointer-events: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: -10 !important;
}

.mentions__highlighter[style], .mentions__input[style] {
    padding: 5px 5px 5px 5px !important;
    font-size: 14px !important;
    color: #3a414c;
    box-sizing: border-box !important;
    min-height: 30px !important;
    line-height: 18px !important;
    z-index: 2;
    float: left;
    width: 100% !important;
    margin-bottom: 0;
    white-space: pre-wrap !important;
    word-wrap: break-word !important;
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    height: inherit !important;
}

.mentions {
    display: flex;
    flex-flow: row;
    flex: 1 1;
    max-width: 100%;
    overflow-y: hidden;
    padding-bottom: 6px;
}

.mentions-min-height > .mentions__control > .mentions__input,
.mentions-min-height > .mentions__control > .mentions__highlighter {
    min-height: 50px !important;
}

.mentions__control {
    position: relative;
    z-index: 0;
    border-collapse: separate;
    width: 100%;
    max-width: 100%;
}

.mentions__control:focus {
    border-color: #0088A9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 0px #74d4c2;
    outline: none;
}

.disabled textarea:focus {
    box-shadow: none;
}

.disabled textarea {
    margin: 1px;
    pointer-events: all;
    border: none;
    outline: none;
    box-shadow: none;
    -webkit-user-select: text !important;
       -moz-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
    color: transparent;
    display: inline-block;
    font-size: 2em;
    text-shadow: 0 0 0 black;
    width: 2em;
}

textarea {
    resize: vertical;
    -webkit-user-select: text !important;
       -moz-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
}

.mentions__input {
    border: 1px solid #c4cdd6;
    border-radius: 4px;
}

.mentions__input:focus {
    border-color: #0088A9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 0px #74d4c2;
    outline: none;
}

.mentions__suggestions {
    margin-top: 22px !important;
    font-size: 14px;
    line-height: 1.286;
    color: #3a414c;
    background-color: #fff;
    background-image: none;
    border: 1px solid #c4cdd6;
    border-radius: 2px;
    position: relative;
    z-index: 6 !important;
    float: left;
    width: 100%;
    margin-bottom: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.mentions__suggestions__item__display {
    padding: 5px;
}

.mentions__suggestions__item--focused {
    background-color: #c4cdd6;

}

.mentions-edit {
    margin-left: auto;
    position: relative;
    justify-content: flex-end;
    align-content: flex-end;
    font-size: 18px;
    top: 8px;
    right: 0px;
    background-color: white;
    padding-left: 5px;
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
}

.mentions-ok {
    justify-content: flex-end;
    align-content: flex-end;
    margin-left: auto;
    position: relative;
    font-size: 18px;
    top: 5px;
    color: green;
    background-color: white;
    padding-left: 2px;
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
}

.mentions-cancel {
    justify-content: flex-end;
    align-content: flex-end;
    margin-left: auto;
    position: relative;
    font-size: 20px;
    top: 5px;
    color: red;
    background-color: white;
    padding-left: 2px;
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
}

.mentions-delete {
    justify-content: flex-end;
    align-content: flex-end;
    margin-left: auto;
    position: relative;
    font-size: 20px;
    top: 5px;
    color: red;
    background-color: white;
    padding-left: 2px;
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
}

.mentions__highlighter > strong, .mentions__highlighter__caret > strong {
    background-color: seashell;
}

.input-group-btn {
    vertical-align: top;
}

div:focus {
    outline-style: none;
}

.rcc-tooltip-wide .tooltip-inner {
    max-width: 35em;
}

.rcw-main {
    display: block;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 4px;
}

.comments-add .form-group {
    margin-bottom: 2px !important;
}

.comments-add {
    background-color: white;
    margin: 0;
    padding: 0;
}

.post-button-inline {
    display: flex;
}

.post-button-inline > div {
    flex-grow: 1;
    /*min-height: 56px;*/
}

.post-button-inline > button {
    margin-left: 10px;
    height: 50px;
}

.Select {
  position: relative; }

.Select,
.Select div,
.Select input,
.Select span {
  box-sizing: border-box; }

.Select.is-disabled > .Select-control {
  background-color: #f9f9f9; }

.Select.is-disabled > .Select-control:hover {
  box-shadow: none; }

.Select.is-disabled .Select-arrow-zone {
  cursor: default;
  pointer-events: none;
  opacity: 0.35; }

.Select-control {
  background-color: #ffffff;
  border-color: #c4cdd6;
  border-radius: 4px;
  border: 1px solid #c4cdd6;
  color: #3a414c;
  cursor: default;
  display: flex;
  border-spacing: 0;
  border-collapse: separate;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%; }

.Select-control .Select-input:focus {
  outline: none; }

.is-searchable.is-open > .Select-control {
  cursor: text; }

.is-open > .Select-control {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: #fff;
  border-color: #c4cdd6; }

.is-open > .Select-control .Select-arrow {
  top: -1px; }

.is-searchable.is-focused:not(.is-open) > .Select-control {
  cursor: text; }

.is-focused:not(.is-open) > .Select-control {
  border-color: #41B5DC; }

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  bottom: 0;
  color: #8090a2;
  left: 0;
  line-height: 48px;
  padding-left: 16px;
  padding-right: 16px;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.form-group .Select--single > .Select-control .Select-value {
  padding-top: 26px;
  line-height: 19px; }

.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: #3a414c; }

.has-value.Select--single > .Select-control .Select-value a.Select-value-label,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label {
  cursor: pointer;
  text-decoration: none; }

.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
  color: #0088A9;
  outline: none;
  text-decoration: underline; }

.Select-input {
  height: 48px;
  padding-left: 16px;
  padding-right: 16px;
  vertical-align: middle; }

.Select-input > input {
  width: 100%;
  background: none transparent;
  border: 0 none;
  box-shadow: none;
  cursor: default;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: none;
  line-height: 14px;
  padding: 16px 0; }

.form-group.form-group-active .Select-input > input {
  padding-left: 0;
  width: 100% !important; }

.is-focused .Select-input > input {
  cursor: text; }

.has-value.is-pseudo-focused .Select-input {
  opacity: 0; }

.Select-control:not(.is-searchable) > .Select-input {
  outline: none; }

.Select-loading-zone {
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 24px;
  align-self: center; }

.Select-loading {
  -webkit-animation: Select-animation-spin 400ms infinite linear;
          animation: Select-animation-spin 400ms infinite linear;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid #c4cdd6;
  border-right-color: #0088A9;
  display: inline-block;
  position: relative;
  vertical-align: middle; }

.Select-clear-zone {
  -webkit-animation: Select-animation-fadeIn 200ms;
          animation: Select-animation-fadeIn 200ms;
  color: #0088A9;
  cursor: pointer;
  width: 20px;
  align-self: center;
  z-index: 3; }

.Select-clear-zone:hover {
  color: #00738E; }

.Select-clear {
  display: inline-block;
  font-size: 20px;
  line-height: 1; }

.Select--multi .Select-clear-zone {
  width: 17px; }

.Select-arrow-zone {
  align-self: center;
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 16px;
  margin-right: 17px;
  margin-left: 6px; }

.Select-arrow {
  height: 0;
  width: 0;
  position: relative;
  color: #0088A9;
  border: none;
  height: auto;
  margin: 0; }
  .Select-arrow:after {
    font-size: 18px;
    font-family: "FontAwesome";
    content: "\F107"; }

.is-open .Select-arrow:after {
  font-size: 18px;
  font-family: "FontAwesome";
  content: "\F106"; }

.Select-multi-value-wrapper {
  flex-grow: 1; }

.Select .Select-aria-only {
  display: inline-block;
  height: 1px;
  width: 1px;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  float: left; }

@-webkit-keyframes Select-animation-fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes Select-animation-fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.Select-menu-outer {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #c4cdd6;
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 212px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 6;
  -webkit-overflow-scrolling: touch; }

.Select-menu {
  max-height: 210px;
  overflow-y: auto; }

.Select-option, .VirtualizedSelectOption {
  box-sizing: border-box;
  background-color: #fff;
  color: #0088A9;
  cursor: pointer;
  display: block;
  padding: 12px 10px; }

.Select-option:last-child, .VirtualizedSelectOption:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }

.Select-option.is-selected, .VirtualizedSelectSelectedOption {
  background-color: #edf3f9; }

.Select-option.is-focused, .VirtualizedSelectFocusedOption {
  background-color: #f8fafd;
  color: #00738E; }

.Select-option.is-disabled, .VirtualizedSelectDisabledOption {
  background-color: #F7F9FA;
  color: #c4cdd6;
  cursor: default; }

.Select-noresults {
  box-sizing: border-box;
  color: #8090a2;
  cursor: default;
  display: block;
  padding: 12px 10px; }

.Select--multi .Select-input {
  vertical-align: middle;
  margin-left: 10px;
  padding: 0; }

.Select--multi.has-value .Select-input {
  margin-left: 5px; }

.Select--multi .Select-value {
  margin-left: 9px;
  margin-top: 11px;
  background-color: #edf3f9;
  border: 1px solid #c4cdd6;
  border-radius: 2px;
  color: #697582;
  display: inline-block;
  font-size: 0.9em;
  line-height: 1.4;
  vertical-align: top; }

.form-group-active .Select--multi .Select-multi-value-wrapper {
  padding-left: 7px; }

.form-group-active .Select--multi .Select-value {
  margin-top: 21px; }

.Select--multi .Select-value-icon,
.Select--multi .Select-value-label {
  display: inline-block;
  vertical-align: middle; }

.Select--multi .Select-value-label {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  cursor: default;
  padding: 2px 5px; }

.Select--multi a.Select-value-label {
  color: #0088A9;
  cursor: pointer;
  text-decoration: none; }

.Select--multi .Select-value-icon {
  cursor: pointer;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  padding: 1px 5px 3px;
  color: #0088A9;
  font-size: 14px;
  font-weight: bold; }

.Select--multi .Select-value-icon:hover,
.Select--multi .Select-value-icon:focus {
  color: #00738E; }

.Select--multi.is-disabled .Select-value {
  background-color: #F7F9FA;
  color: #c4cdd6;
  border: 1px solid #c4cdd6;
  color: #3a414c; }

.Select--multi.is-disabled .Select-value-icon {
  cursor: not-allowed;
  border-right: 1px solid #c4cdd6; }

.Select--multi.is-disabled .Select-value-icon:hover,
.Select--multi.is-disabled .Select-value-icon:focus,
.Select--multi.is-disabled .Select-value-icon:active {
  background-color: #F7F9FA; }

@keyframes Select-animation-spin {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn); } }

@-webkit-keyframes Select-animation-spin {
  to {
    -webkit-transform: rotate(1turn); } }

/*
  Styling exclusive to `react-select-plus`.
  https://github.com/HubSpot/react-select-plus
*/
.Select-option-group-label {
  color: #697582;
  font-weight: bold;
  padding: 8px 10px; }

.Select-option-group-label ~ .Select-option,
.Select-option-group-label ~ .Select-option-group {
  padding-left: 20px; }

/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsImZpbGUiOiJzZWxlY3QuY3NzIiwic291cmNlUm9vdCI6IiJ9*/


/* HACK */
.comments-add .Select-control {
  border-right: 0px;
  border-radius: 4px 0px 0px 4px;
}
/* END HACK */
.comment-header-icon {
  padding-left: 6px;
}

.UserAvatar {
    display: inline-block;
    color: white;
    margin-top: 5px;

}

.UserAvatar--inner {
    border-radius: 25% !important;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.UserAvatar--light {
    color: gray;
}

.UserAvatar--light .UserAvatar--inner {
    border: 1px solid lightgray;
}

.UserAvatar--img {
    float: left;
    width: 50px;
    height: 50px;
    border-radius: 25% !important;
    margin-right: 5px;
    display: inline-block;
}

.visibility-option-icon {
  padding-right: 6px;
}

.watch-icon-loading {
    color: #0088a9;
    cursor: progress;
}

.watch-icon {
    color: #0088a9;
}

.watch-icon-error {
    color: red
}

.watch-icon-subscribed {
    color: green;
}

.watch-icon-unsubscribed {
    color: darkgreen;
}

.watch-icon-clickable:hover {
    color: black;
    cursor: pointer
}

.watch-icon-clickable:active {
    color: black;
}

.rcw-main a {
    cursor: pointer;
}

.chat {
    display: flex;
    flex-flow: column wrap;
    flex: 1 1 auto;
}

.mentions-ok, .mentions-cancel, .mentions-edit, .mentions-delete {
    background-color: transparent;
    text-align: center;
}

.bubble {
    border-radius: 6px;
    padding: 0px;
    position: relative;
    vertical-align: top;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

/* me */

.left .mentions {
    padding-bottom: 2px;
}

.left .mentions__control {
    margin-left: 4px;
}

.left.comment-author {
    text-align: left;
    max-width: unset;
    margin-left: 5px;
    margin-bottom: 2px;
    font-weight: bold;
}

.left.comment-creator {
    text-align: left;
    max-width: unset;
    margin-left: 5px;
    margin-bottom: 2px;
    display: block;
}

.left.bubble {
    background-color: #c8edf4;
    margin: 5px auto 1px 5px;
    padding-right: 5px;
    padding-top: 2px;
}

.left .mentions__suggestions__item--focused {
    background-color: #DDF4F8;
}

.right .mentions__suggestions__item--focused {
    background-color: #FAEDF8;
}

.left.bubble:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 2px;
    width: 0;
    height: 0;
    border: 11px solid transparent;
    border-right-color: #c8edf4;
    border-left: 0;
    margin-top: 0px;
    margin-left: -11px;
}

.left .mentions__highlighter > strong {
    background-color: #DDF4F8;
    border-radius: 4px;
    padding: 0px 1px 2px 1px;
}

.left .mentions__suggestions__list {
    background-color: #c8edf4;
}

.right .mentions {
    padding-bottom: 2px;
}

.right.comment-author {
    text-align: right;
    max-width: unset;
    margin-right: 5px;
    margin-bottom: 2px;
    font-weight: bold;
}

.left.comment-author, .right.comment-author {
    margin-top: 5px;
}

.right.comment-creator {
    text-align: right;
    max-width: unset;
    margin-right: 5px;
    margin-bottom: 2px;
    display: block;
}

.right.bubble {
    background-color: #f4d9f0;
    margin: 2px 5px 2px auto;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;

}

.right.bubble:after {
    content: '';
    position: absolute;
    right: 4px;
    top: 2px;
    width: 0;
    height: 0;
    border: 11px solid transparent;
    border-left-color: #f4d9f0;
    border-right: 0;
    margin-top: 0px;
    margin-right: -11px;
}

.right .mentions__highlighter > strong {
    background-color: #FAEDF8;
    border-radius: 4px;
    padding: 0px 1px 2px 1px;
}

.right .mentions__suggestions__list {
    background-color: #f4d9f0;
}

