.chat {
    display: flex;
    flex-flow: column wrap;
    flex: 1 1 auto;
}

.mentions-ok, .mentions-cancel, .mentions-edit, .mentions-delete {
    background-color: transparent;
    text-align: center;
}

.bubble {
    border-radius: 6px;
    padding: 0px;
    position: relative;
    vertical-align: top;
    width: fit-content;
}

/* me */

.left .mentions {
    padding-bottom: 2px;
}

.left .mentions__control {
    margin-left: 4px;
}

.left.comment-author {
    text-align: left;
    max-width: unset;
    margin-left: 5px;
    margin-bottom: 2px;
    font-weight: bold;
}

.left.comment-creator {
    text-align: left;
    max-width: unset;
    margin-left: 5px;
    margin-bottom: 2px;
    display: block;
}

.left.bubble {
    background-color: #c8edf4;
    margin: 5px auto 1px 5px;
    padding-right: 5px;
    padding-top: 2px;
}

.left .mentions__suggestions__item--focused {
    background-color: #DDF4F8;
}

.right .mentions__suggestions__item--focused {
    background-color: #FAEDF8;
}

.left.bubble:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 2px;
    width: 0;
    height: 0;
    border: 11px solid transparent;
    border-right-color: #c8edf4;
    border-left: 0;
    margin-top: 0px;
    margin-left: -11px;
}

.left .mentions__highlighter > strong {
    background-color: #DDF4F8;
    border-radius: 4px;
    padding: 0px 1px 2px 1px;
}

.left .mentions__suggestions__list {
    background-color: #c8edf4;
}

.right .mentions {
    padding-bottom: 2px;
}

.right.comment-author {
    text-align: right;
    max-width: unset;
    margin-right: 5px;
    margin-bottom: 2px;
    font-weight: bold;
}

.left.comment-author, .right.comment-author {
    margin-top: 5px;
}

.right.comment-creator {
    text-align: right;
    max-width: unset;
    margin-right: 5px;
    margin-bottom: 2px;
    display: block;
}

.right.bubble {
    background-color: #f4d9f0;
    margin: 2px 5px 2px auto;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;

}

.right.bubble:after {
    content: '';
    position: absolute;
    right: 4px;
    top: 2px;
    width: 0;
    height: 0;
    border: 11px solid transparent;
    border-left-color: #f4d9f0;
    border-right: 0;
    margin-top: 0px;
    margin-right: -11px;
}

.right .mentions__highlighter > strong {
    background-color: #FAEDF8;
    border-radius: 4px;
    padding: 0px 1px 2px 1px;
}

.right .mentions__suggestions__list {
    background-color: #f4d9f0;
}
